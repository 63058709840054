import React, { useEffect, useState, useRef, useCallback } from "react";

import { Box, Button, CircularProgress } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { Typography } from "@mui/joy";
import DeleteIcon from "@mui/icons-material/Delete";
import PlaylistAddIcon from "@mui/icons-material/PlaylistAdd";
import PlaylistAddCheckIcon from "@mui/icons-material/PlaylistAddCheck";

import { api2 } from "state/api";
import useAxiosPrivate from "hooks/useAxiosPrivate";

import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";

import { useTheme } from "@emotion/react";

import ModalStyle from "components/ModalStyle";
import Header from "components/Header";
import Input from "components/Input";
import FlexBetween from "components/FlexBetween";

const FirstReaderTags = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const axiosPrivate = useAxiosPrivate();

  const [item, setItem] = useState("");
  const [observation, setObservation] = useState("");
  const [unregistredTagsObservation, setUnregistredTagsObservation] =
    useState("");

  const [data, setData] = useState([]);

  const [loading, setLoading] = useState(true);
  const [isConfirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [isUnregisteredTagsModalOpen, setUnregisteredTagsModalOpen] =
    useState(false);
  const [isFinalizeModalOpen, setFinalizeModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [itemId, setItemId] = useState(null);

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const firstReaderId = searchParams.get("leitura");

  let itemName = useRef();

  const loadData = useCallback(async () => {
    try {
      const response = await axiosPrivate.get(
        `api/first-reader/${firstReaderId}`
      );
      setData(response.data);
      setLoading(false);
    } catch (error) {
      if (error.response.status === 400) {
        showToastWarning(error.response.data.error);
      } else if (error.response.status === 401) {
        showToastError("Acesso expirado. Faça o login novamente.");
        navigate("/");
      } else {
        console.error("Erro ao carregar dados da tabela:", error);
        showToastError(error.response.data.error);
      }
      setLoading(false);
    }
  }, [axiosPrivate, firstReaderId, navigate]);

  async function handleDeleteClick() {
    try {
      const response = await axiosPrivate.delete(
        `api/first-reader/item/delete/${itemId}/${firstReaderId}`
      );
      if (response.status === 200) {
        loadData();
        showToastSuccess(response.data.msg);
      } else {
        showToastError(response.data?.error);
      }
    } catch (error) {
      switch (error.response.status) {
        case 400:
          showToastWarning(error.response.data.error);
          break;
        case 401:
          showToastError("Acesso expirado. Faça o login novamente.");
          navigate("/");
          break;
        default:
          console.error("Erro ao carregar dados da tabela:", error);
          showToastError(
            error.response.data.error || "Erro interno no servidor"
          );
      }
    }
  }

  async function handleUpdateObservation() {
    try {
      const response = await axiosPrivate.put(`api/first-reader/item/${item}`, {
        unregistredTagsObservation,
      });

      if (response.status === 200) {
        loadData();
        showToastSuccess(response.data.msg);
        setUnregistredTagsObservation("");
        setItem("");
      } else {
        showToastError(response.data?.error);
      }
    } catch (error) {
      switch (error.response.status) {
        case 400:
          showToastWarning(error.response.data.error);
          break;
        case 401:
          showToastError("Acesso expirado. Faça o login novamente.");
          navigate("/");
          break;
        default:
          console.error("Erro ao carregar dados da tabela:", error);
          showToastError(
            error.response.data.error || "Erro interno no servidor"
          );
      }
    }
  }

  const showToastSuccess = (message) => {
    toast.success(message, {
      position: toast.POSITION.TOP_CENTER,
    });
  };

  const showToastWarning = (message) => {
    toast.warning(message, {
      position: toast.POSITION.TOP_CENTER,
    });
  };

  const showToastError = (message) => {
    toast.error(message, {
      position: toast.POSITION.TOP_CENTER,
    });
  };

  useEffect(() => {
    itemName.current.focus();
    loadData();
  }, [loadData]);

  const columns = [
    {
      field: "name",
      headerName: "Item",
      flex: 1,
    },
    {
      field: "description",
      headerName: "Descrição",
      flex: 1,
    },
    {
      field: "serialNumber",
      headerName: "Número de série",
      flex: 1,
    },
    {
      field: "tag",
      headerName: "Número de patrimônio",
      flex: 1,
    },
    {
      field: "Ação",
      flex: 0.3,
      renderCell: (cellValues) => (
        <>
          <Button
            variant="text"
            color="error"
            onClick={() => {
              setConfirmationModalOpen(true);
              setItemId(cellValues.row.itemId);
            }}
          >
            <DeleteIcon />
          </Button>
        </>
      ),
    },
  ];

  async function handleSubmit() {
    try {
      const response = await axiosPrivate.post("api/first-reader/finalize", {
        firstReaderId,
        observation,
      });

      showToastSuccess(response.data.msg);
      navigate("/primeira-leitura");
    } catch (error) {
      switch (error.response.status) {
        case 400:
          showToastWarning(error.response.data.error);
          break;
        case 401:
          showToastError("Acesso expirado. Faça o login novamente.");
          navigate("/");
          break;
        default:
          showToastError(error.response.data.error);
      }
    }
  }

  const addItem = async () => {
    try {
      setIsLoading(true);

      const response = await axiosPrivate.get(
        `api/first-reader/item/${item}/${firstReaderId}`
      );
      const itemId = response.data.itemId;

      await axiosPrivate.post("api/first-reader/item", {
        itemId,
        firstReaderId,
      });
      loadData();
      setItem("");
    } catch (error) {
      switch (error.response.status) {
        case 400:
          showToastWarning(error.response.data.error);
          setItem("");
          break;
        case 401:
          showToastError("Acesso expirado. Faça o login novamente.");
          navigate("/");
          break;
        case 404:
          showToastWarning(error.response.data.error);
          setUnregisteredTagsModalOpen(true);
          break;
        case 409:
          showToastWarning(error.response.data.error);
          break;
        default:
          showToastError(error.response.data.error);
          console.error("Erro ao buscar informações do item: ", error);
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Box m="1.5rem 2.5rem">
      <Header title="PRIMEIRA LEITURA" subtitle={`Leitura: ${firstReaderId}`} />
      <ModalStyle
        open={isUnregisteredTagsModalOpen}
        onClose={() => setUnregisteredTagsModalOpen(false)}
      >
        <Typography sx={{ mb: "30px" }}>
          Observação do patrimônio não cadastrado
        </Typography>
        <Input
          type="text"
          label="Observação"
          value={unregistredTagsObservation}
          onChange={(e) => setUnregistredTagsObservation(e.target.value)}
        />
        <FlexBetween>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => {
              handleUpdateObservation();
              setUnregisteredTagsModalOpen(false);
            }}
          >
            Confirmar
          </Button>
          <Button
            variant="contained"
            color="error"
            onClick={() => setUnregisteredTagsModalOpen(false)}
          >
            Cancelar
          </Button>
        </FlexBetween>
      </ModalStyle>
      <ModalStyle
        open={isConfirmationModalOpen}
        onClose={() => setConfirmationModalOpen(false)}
      >
        <Typography sx={{ mb: "30px" }}>
          Tem certeza de que deseja excluir este item?
        </Typography>
        <FlexBetween>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => {
              handleDeleteClick();
              setConfirmationModalOpen(false);
            }}
          >
            Confirmar
          </Button>
          <Button
            variant="contained"
            color="error"
            onClick={() => setConfirmationModalOpen(false)}
          >
            Cancelar
          </Button>
        </FlexBetween>
      </ModalStyle>
      <ModalStyle
        open={isFinalizeModalOpen}
        onClose={() => setFinalizeModalOpen(false)}
      >
        <Typography sx={{ mb: "11px" }}>
          Tem certeza de que deseja finalizar esta primeira leitura?
        </Typography>
        <Typography
          sx={{ mb: "25px", fontSize: "13px", color: "warning.light" }}
        >
          Não será possível realizar a primeira leitura desta localização
          novamente.
        </Typography>
        <Input
          type="text"
          label="Observação"
          value={observation}
          onChange={(e) => setObservation(e.target.value)}
        />
        <FlexBetween>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => {
              handleSubmit();
              setConfirmationModalOpen(false);
            }}
          >
            Confirmar
          </Button>
          <Button
            variant="contained"
            color="error"
            onClick={() => setFinalizeModalOpen(false)}
          >
            Cancelar
          </Button>
        </FlexBetween>
      </ModalStyle>
      <Box sx={{ mt: "25px" }}>
        <Input
          type="text"
          value={item}
          refInput={itemName}
          onChange={(e) => setItem(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              e.preventDefault();
              addItem();
            }
          }}
          label="Faça aqui a leitura do patrimônio..."
        />
        <Box sx={{ mb: "15px" }}>
          <Button
            disabled={isLoading}
            sx={{ mr: "5px" }}
            variant="contained"
            color="secondary"
            endIcon={<PlaylistAddIcon />}
            onClick={addItem}
          >
            {isLoading && (
              <CircularProgress
                size={24}
                sx={{
                  color: "secondary",
                  transform: "translate(-50%, -50%)",
                  marginRight: "2px",
                }}
              />
            )}
            {!isLoading ? "Adicionar" : "Aguarde..."}
          </Button>
          <Button
            disabled={isLoading}
            onClick={() => setFinalizeModalOpen(true)}
            color="error"
            endIcon={<PlaylistAddCheckIcon />}
            variant="contained"
          >
            Finalizar
          </Button>
        </Box>
      </Box>
      <Box
        height="80vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: theme.palette.primary.light,
            overflowY: "auto",
            scrollbarWidth: "thin",
            "&::-webkit-scrollbar": {
              width: "3px",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "#b3b0b0",
              borderRadius: "20px",
            },
            "&::-webkit-scrollbar-track": {
              backgroundColor: "transparent",
            },
          },
          "& .MuiDataGrid-FooterContainer": {
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
            borderTop: "none",
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${theme.palette.secondary[200]} !important`,
          },
        }}
      >
        <DataGrid
          getRowId={(row) => row.itemId}
          rows={data}
          columns={columns}
        />
      </Box>
    </Box>
  );
};

export default FirstReaderTags;
