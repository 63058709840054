const { Box } = require("@mui/material");
const { styled } = require("@mui/system");

const ResponsiveFlexBetween = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",

  "@media (max-width: 600px)": {
    flexDirection: "column", 
    padding: "16px",
  },
});

export default ResponsiveFlexBetween;
