import React, { useEffect, useState } from "react";
import { Box, CircularProgress, useTheme } from "@mui/material";
import { api2 } from "state/api";
import { ResponsiveBar } from "@nivo/bar";

const ItemGroupChart = ({ isDashboard = false }) => {
  const theme = useTheme();

  const [itemGroup, setItemGroup] = useState([]);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    api2.get("api/dashboard/item/itemByItemGroup").then((response) => {
      setLoading(false);
      setItemGroup(response.data);
    });
  }, []);

  const colors = [
    theme.palette.secondary[300],
    theme.palette.secondary[400],
    theme.palette.secondary[500],
    theme.palette.secondary[600],
    theme.palette.secondary[700]
  ];

  const formattedData = itemGroup.map((item, i) => ({
    id: item.itemGroup,
    label: item._id,
    value: item.totalValue,
    color: colors[i],
  }));

  return (
    <Box
      height={isDashboard ? "500px" : "100%"}
      width={undefined}
      minHeight={isDashboard ? "325px" : undefined}
      minWidth={isDashboard ? "400px" : undefined}
      position="relative"
    >
      {loading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "50vh",
          }}
        >
          <CircularProgress color="secondary" />
        </Box>
      ) : (
        <ResponsiveBar
          data={formattedData}
          axisLeft={{
            tickRotation: 20
          }}
          theme={{
            axis: {
              domain: {
                line: {
                  stroke: theme.palette.secondary[200],
                },
              },
              legend: {
                text: {
                  fill: theme.palette.secondary[200],
                },
              },
              ticks: {
                line: {
                  stroke: theme.palette.secondary[200],
                  strokeWidth: 1,
                },
                text: {
                  fill: theme.palette.secondary[200],
                  fontSize: isDashboard ? 8.25 : 12,
                },
              },
            },
            legends: {
              text: {
                fill: theme.palette.secondary[200],
              },
            },
            tooltip: {
              container: {
                color: theme.palette.primary.main,
              },
            },
          }}
          colors={{ datum: "data.color" }}
          margin={
            isDashboard
              ? { top: 40, right: 20, bottom: 80, left: 150 }
              : { top: 40, right: 80, bottom: 80, left: 80 }
          }
          sortByValue={true}
          innerRadius={0.45}
          activeOuterRadiusOffset={8}
          borderWidth={1}
          borderColor={{
            from: "color",
            modifiers: [["darker", 0.2]],
          }}
          enableArcLinkLabels={!isDashboard}
          arcLinkLabelsTextColor={theme.palette.secondary[200]}
          arcLinkLabelsThickness={2}
          arcLinkLabelsColor={{ from: "color" }}
          arcLabelsSkipAngle={10}
          arcLabelsTextColor={{
            from: "color",
            modifiers: [["darker", 2]],
          }}
          layout="horizontal"
          enableGridY={false}
          // legends={[
          //   {
          //     anchor: isDashboard ? "bottom" : "left",
          //     direction: "column",
          //     justify: false,
          //     translateX: isDashboard ? 20 : 0,
          //     translateY: isDashboard ? 50 : 56,
          //     itemsSpacing: 7,
          //     itemWidth: 85,
          //     itemHeight: 18,
          //     itemTextColor: "#999",
          //     itemDirection: "left-to-right",
          //     itemOpacity: 1,
          //     symbolSize: 18,
          //     symbolShape: "circle",
          //     effects: [
          //       {
          //         on: "hover",
          //         style: {
          //           itemTextColor: theme.palette.primary[500],
          //         },
          //       },
          //     ],
          //   },
          // ]}
        />
      )}
    </Box>
  );
};

export default ItemGroupChart;
