import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Typography,
  useTheme,
  MenuItem,
  Grid,
  CircularProgress,
  InputLabel,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DataGrid } from "@mui/x-data-grid";

import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import SendIcon from "@mui/icons-material/Send";
import EditIcon from "@mui/icons-material/Edit";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import BookmarkRemoveOutlinedIcon from "@mui/icons-material/BookmarkRemoveOutlined";
import ToggleOffOutlinedIcon from "@mui/icons-material/ToggleOffOutlined";
import ToggleOnIcon from "@mui/icons-material/ToggleOn";

import { toast } from "react-toastify";
import dayjs from "dayjs";
import locale from "dayjs/locale/pt-br";

import Header from "components/Header";
import ResponsiveFlexBetween from "components/ResponsiveFlexBetween";
import DataGridCustomToolbar from "components/DataGridCustomToolbar";
import ModalStyle from "components/ModalStyle";
import Input from "components/Input";
import Dropdown from "components/Dropdown";
import GridToolbar from "components/GridToolbar";

import useAxiosPrivate from "hooks/useAxiosPrivate";

const Items = () => {
  // Declaração das variáveis
  const theme = useTheme();
  const api = useAxiosPrivate();

  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(20);

  const [item, setItem] = useState([]);
  const [locationSelect, setLocationSelect] = useState([]);
  const [itemGroupData, setItemGroupData] = useState([]);
  const [costCenterData, setCostCenterData] = useState([]);
  const [branchData, setBranchData] = useState([]);

  const [branch, setBranch] = useState("");
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [writeOffDate, setWriteOffDate] = useState("");
  const [value, setValue] = useState("");
  const [responsable, setResponsable] = useState("");
  const [location, setLocation] = useState("");
  const [supplier, setSupplier] = useState("");
  const [serialNumber, setSerialNumber] = useState("");
  const [tag, setTag] = useState("");
  const [costCenter, setCostCenter] = useState("");
  const [itemGroup, setItemGroup] = useState("");
  const [invoice, setInvoice] = useState("");
  const [searchInput, setSearchInput] = useState("");

  const [editItem, setEditItem] = useState(null);
  const [itemImage, setItemImage] = useState("");
  const [acquisitionDate, setAcquisitionDate] = useState(null);
  const [inactivateItem, setInactivateItem] = useState(null);

  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [openWritteOff, setOpenWritteOff] = useState(false);
  const [isConfirmationModalOpen, setConfirmationModalOpen] = useState(false);

  const [columnVisibilityModel, setColumnVisibilityModel] = useState({
    branch: false,
    description: false,
    serialNumber: false
  });

  // Funções utilizadas
  const handleOpen = (item = null) => {
    setEditItem(item);
    setName(item ? item.name : "");
    setDescription(item ? item.description : "");
    setValue(item ? item.value : "");
    setSupplier(item ? item.supplier : "");
    setLocation(item ? item.locationId : "");
    setSerialNumber(item ? item.serialNumber : "");
    setTag(item ? item.tag : "");
    setBranch(item ? item.branchId : "");
    setResponsable(item ? item.responsable : "");
    setInvoice(item ? item.invoice : "");
    setItemGroup(item ? item.itemGroupId : "");
    setCostCenter(item ? item.costCenterId : "");

    setAcquisitionDate(
      item ? dayjs(item.acquisitionDate, { format: "DD/MM/YYYY" }) : null
      );
      
      setOpen(true);
    };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpenWritteOff = () => {
    setOpenWritteOff(true);
  };

  const handleCloseWritteOff = () => {
    setOpenWritteOff(false);
  };

  const handleImageChange = (e) => {
    const selectedImage = e.target.files[0];
    if (selectedImage) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setItemImage(e.target.result);
      };
      reader.readAsDataURL(selectedImage);
    }
  };

  const showToastSuccess = (message) => {
    toast.success(message, {
      position: toast.POSITION.TOP_CENTER,
    });
  };

  const showToastError = (message) => {
    toast.error(message, {
      position: toast.POSITION.TOP_CENTER,
    });
  };

  const loadData = async () => {
    try {
      const response = await api.get("api/item");
      setItem(response.data);
      setLoading(false);
    } catch (error) {
      console.error("Erro ao carregar dados da tabela:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    api
      .get("api/location/active")
      .then((response) => setLocationSelect(response.data));

    api
      .get("api/item-group/active")
      .then((response) => setItemGroupData(response.data));

    api
      .get("api/cost-center/active")
      .then((response) => setCostCenterData(response.data));

    api
      .get("api/branch/active")
      .then((response) => setBranchData(response.data));

    loadData();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      if (editItem && editItem._id) {
        const response = await api.put(`api/item/${editItem._id}`, {
          branch,
          name,
          description,
          value,
          supplier,
          serialNumber,
          tag,
          acquisitionDate,
          costCenter,
        });
        showToastSuccess(response.data.msg);
      } else {
        const response = await api.post("api/item/", {
          branch,
          name,
          description,
          value,
          responsable,
          location,
          supplier,
          serialNumber,
          tag,
          acquisitionDate,
          itemGroup,
          invoice,
          costCenter,
        });
        showToastSuccess(response.data.msg);
      }

      setEditItem(null);
      setOpen(false);
      loadData();
    } catch (error) {
      showToastError(error.response.data.error);
    }
  };

  const writeOffSubmit = async () => {
    try {
      if (!editItem || !editItem._id) {
        showToastError("Item inválido para baixa.");
        return;
      }

      const response = await api.put(
        `api/items/write-off/${editItem._id}`,
        {
          writeOffDate,
        }
      );

      if (response && response.status === 200) {
        showToastSuccess(response.data.msg);
        setOpenWritteOff(false);
        setEditItem(null);
        loadData();
      } else {
        showToastError(
          response.data.error || "Erro desconhecido durante a baixa do item."
        );
      }
    } catch (error) {
      showToastError(
        error.response?.data?.error ||
          "Erro desconhecido durante a baixa do item."
      );
    }
  };

  async function handleActivateOrInactivateClick() {
    try {
      let response;

      if (inactivateItem.active === "Ativo") {
        response = await api.put(`api/item/inactive/${inactivateItem._id}`);
      } else if (inactivateItem.active === "Inativo") {
        response = await api.put(`api/item/active/${inactivateItem._id}`);
      }

      if (response && response.status === 200) {
        loadData();
        showToastSuccess(response.data.msg);
      } else {
        showToastError(response.data.error);
      }
    } catch (error) {
      showToastError(error.response.data.error);
    }
  }

  // Colunas do datagrid
  const columns = [
    {
      field: "branch",
      headerName: "Filial",
      flex: 0.6,
      visibility: false,
    },
    {
      field: "name",
      headerName: "Item",
      flex: 1,
    },
    {
      field: "description",
      headerName: "Descrição",
      flex: 1,
    },
    {
      field: "value",
      headerName: "Valor (R$)",
      flex: 0.7,
      valueGetter: (params) => {
        const formattedValue = parseFloat(params.row.value).toLocaleString(
          "pt-BR",
          {
            style: "currency",
            currency: "BRL",
            minimumFractionDigits: 2,
          }
        );
        return formattedValue;
      },
    },
    {
      field: "responsable",
      headerName: "Usuário",
      flex: 0.7,
    },
    {
      field: "location",
      headerName: "Localização",
      flex: 0.5,
    },
    {
      field: "supplier",
      headerName: "Fornecedor",
      flex: 1,
    },
    {
      field: "serialNumber",
      headerName: "Nº de série",
      flex: 0.5,
    },
    {
      field: "tag",
      headerName: "Patrimônio",
      flex: 0.6,
    },
    {
      field: "acquisitionDate",
      headerName: "Data de aquisição",
      flex: 0.7,
      valueGetter: (params) => {
        // Formatar a data para DD/MM/YYYY
        const date = new Date(params.row.acquisitionDate);
        const formattedDate = `${date.getDate().toString().padStart(2, '0')}/${(date.getMonth() + 1).toString().padStart(2, '0')}/${date.getFullYear()}`;
        return formattedDate;
      },
    },
    {
      field: "Ação",
      flex: 1.2,
      renderCell: (cellValues) => {
        return (
          <>
            <Button
              variant="text"
              color="secondary"
              onClick={() => handleOpen(cellValues.row)}
            >
              <EditIcon />
            </Button>
            <Button
              variant="text"
              color="error"
              onClick={() => {
                setConfirmationModalOpen(true);
                setInactivateItem(cellValues.row);
              }}
            >
              {cellValues.row.active === "Ativo" ? (
                <ToggleOnIcon />
              ) : (
                <ToggleOffOutlinedIcon />
              )}
            </Button>
          </>
        );
      },
    },
  ];

  return (
    <Box m="1.5rem 2.5rem">
      <ResponsiveFlexBetween>
        <Header
          title="ITENS ATIVOS"
          subtitle="Gerencia a lista de ativos: visualize, cadastre, inative e edite."
        />
        <ModalStyle
          open={isConfirmationModalOpen}
          onClose={() => setConfirmationModalOpen(false)}
        >
          <Typography sx={{ mb: "30px" }}>
            {inactivateItem && inactivateItem.active === "Inativo"
              ? "Tem certeza de que deseja ativar este item?"
              : "Tem certeza que deseja inativar este item?"}
          </Typography>
          <ResponsiveFlexBetween>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => {
                handleActivateOrInactivateClick();
                setConfirmationModalOpen(false);
              }}
            >
              Confirmar
            </Button>
            <Button
              variant="contained"
              color="error"
              onClick={() => setConfirmationModalOpen(false)}
            >
              Cancelar
            </Button>
          </ResponsiveFlexBetween>
        </ModalStyle>
        
        <Box>
          <Button
            onClick={handleOpen}
            startIcon={<AddCircleOutlineIcon />}
            sx={{
              backgroundColor: theme.palette.secondary.dark,
              color: theme.palette.background.alt,
              fontSize: "14px",
              fontWeight: "bold",
              padding: "10px 50px",
              transition: "background-color 0.3s ease, color 0.3s ease",
              "&:hover": {
                backgroundColor: theme.palette.secondary.main,
                color: theme.palette.primary.main,
              },
            }}
          >
            Novo item
          </Button>
          <ModalStyle
            maxHeight="90%"
            width="90%"
            open={open}
            onClose={handleClose}
          >
            <Typography id="modal-modal-title" variant="h5" component="h1">
              {editItem && editItem._id ? "Edição de item" : "Cadastro de item"}
            </Typography>
            <Box display="flex" alignItems="center" justifyContent="flex-start">
              <Button
                sx={{
                  width: "80px",
                  height: "80px",
                  borderRadius: "50%",
                  bgcolor: "lightgray",
                  marginRight: "20px",
                  overflow: "hidden",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <InputLabel htmlFor="imageInput">
                  <input
                    type="file"
                    accept="image/*"
                    style={{ display: "none" }}
                    onChange={handleImageChange}
                    id="imageInput"
                  />
                  {itemImage ? (
                    <img
                      src={itemImage}
                      alt="Imagem do ativo"
                      style={{ width: "100%" }}
                    />
                  ) : (
                    <PhotoCameraIcon fontSize="large" color="secondary" />
                  )}
                </InputLabel>
              </Button>
            </Box>
            <Box id="modal-modal-description" sx={{ mt: 2 }}>
              <form component="form" onSubmit={handleSubmit}>
                <Grid
                  container
                  rowSpacing={{ md: 5 }}
                  columnSpacing={{ md: 2 }}
                  columns={{ xs: 4, sm: 8, md: 12 }}
                >
                  <Grid item xs={4}>
                    <Input
                      type="text"
                      label="Item"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      required
                    />
                  </Grid>
                  <Grid item xs={8}>
                    <Input
                      type="text"
                      label="Descrição do item"
                      value={description}
                      onChange={(e) => setDescription(e.target.value)}
                      required
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Input
                      type="number"
                      label="Valor do ativo"
                      value={value}
                      onChange={(e) => setValue(e.target.value)}
                      required
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <LocalizationProvider
                      dateAdapter={AdapterDayjs}
                      adapterLocale={locale}
                    >
                      <DatePicker
                        type="date"
                        label="Data de aquisição"
                        slotProps={{
                          textField: {
                            helperText:
                              "Preenchido automaticamente com a data atual. Você pode alterar conforme necessário.",
                          },
                        }}
                        format="DD/MM/YYYY"
                        value={acquisitionDate || null}
                        onChange={(newDate) => setAcquisitionDate(newDate)}
                        sx={{ width: "100%", mb: "15px" }}
                      />
                    </LocalizationProvider>
                  </Grid>
                  <Grid item xs={4}>
                    <Input
                      type="text"
                      label="Fornecedor"
                      value={supplier}
                      onChange={(e) => setSupplier(e.target.value)}
                      required
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Input
                      type="text"
                      label="Usuário responsável"
                      value={responsable}
                      onChange={(e) => setResponsable(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Dropdown
                      value={location}
                      onChange={(e) => setLocation(e.target.location)}
                      label="Localização"
                    >
                      {locationSelect.map((location) => (
                        <MenuItem key={location._id} value={location._id}>
                          {location.name}
                        </MenuItem>
                      ))}
                    </Dropdown>
                  </Grid>
                  <Grid item xs={4}>
                    <Input
                      type="text"
                      label="Número de série"
                      value={serialNumber}
                      onChange={(e) => setSerialNumber(e.target.value)}
                      required
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Input
                      type="text"
                      label="Número de patrimônio"
                      value={tag}
                      onChange={(e) => setTag(e.target.value)}
                      required
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Input
                      type="text"
                      label="Nota fiscal"
                      value={invoice}
                      onChange={(e) => setInvoice(e.target.value)}
                      required
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Dropdown
                      type="text"
                      label="Filial"
                      value={branch || ""}
                      onChange={(e) => setBranch(e.target.value)}
                      required
                    >
                      {branchData.map((data) => (
                        <MenuItem key={data._id} value={data._id}>
                          {data.name}
                        </MenuItem>
                      ))}
                    </Dropdown>
                  </Grid>
                  <Grid item xs={4}>
                    <Dropdown
                      type="text"
                      label="Grupo de itens"
                      value={itemGroup || ""}
                      onChange={(e) => setItemGroup(e.target.value)}
                      required
                    >
                      {itemGroupData.map((data) => (
                        <MenuItem key={data._id} value={data._id}>
                          {data.name}
                        </MenuItem>
                      ))}
                    </Dropdown>
                  </Grid>
                  <Grid item xs={4}>
                    <Dropdown
                      type="text"
                      label="Centro de Custo"
                      value={costCenter || ""}
                      onChange={(e) => setCostCenter(e.target.value)}
                    >
                      {costCenterData.map((data) => (
                        <MenuItem key={data._id} value={data._id}>
                          {data.description}
                        </MenuItem>
                      ))}
                    </Dropdown>
                  </Grid>
                </Grid>
                <Button
                  type="submit"
                  variant="contained"
                  color="secondary"
                  endIcon={<SendIcon />}
                  sx={{ mr: 5 }}
                >
                  {editItem && editItem._id ? "Atualizar" : "Cadastrar"}
                </Button>
                <Button
                  onClick={handleOpenWritteOff}
                  endIcon={<BookmarkRemoveOutlinedIcon />}
                  variant="contained"
                  color="error"
                >
                  Dar baixa
                </Button>
                <ModalStyle open={openWritteOff} onClose={handleCloseWritteOff}>
                  <Typography>Dar baixa no item</Typography>
                  <Grid item xs={4}>
                    <Input
                      type="date"
                      value={writeOffDate}
                      onChange={(e) => setWriteOffDate(e.target.value)}
                    />
                    <Button
                      onClick={writeOffSubmit}
                      endIcon={<BookmarkRemoveOutlinedIcon />}
                      variant="contained"
                      color="info"
                      required
                    >
                      Dar baixa
                    </Button>
                  </Grid>
                </ModalStyle>
              </form>
            </Box>
          </ModalStyle>
        </Box>
      </ResponsiveFlexBetween>
      <DataGridCustomToolbar
        value={searchInput}
        onChange={setSearchInput}
        onSearch={(searchInput) => setSearchInput(searchInput)}
      />
      {loading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "80vh",
          }}
        >
          <CircularProgress color="secondary" />
        </Box>
      ) : (
        <Box
          height="80vh"
          sx={{
            "& .MuiDataGrid-root": {
              border: "none",
            },
            "& .MuiDataGrid-cell": {
              borderBottom: "none",
            },
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: theme.palette.background.alt,
              color: theme.palette.secondary[100],
              borderBottom: "none",
            },
            "& .MuiDataGrid-virtualScroller": {
              backgroundColor: theme.palette.primary.light,
              overflowY: "auto",
              "&::-webkit-scrollbar": {
                width: "6px",
              },
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: "#b3b0b0",
                borderRadius: "50px",
              },
              "&::-webkit-scrollbar-track": {
                backgroundColor: "transparent",
              },
            },
            "& .MuiDataGrid-FooterContainer": {
              backgroundColor: theme.palette.background.alt,
              color: theme.palette.secondary[100],
              borderTop: "none",
            },
            "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
              color: `${theme.palette.secondary[200]} !important`,
            },
          }}
        >
          <DataGrid
            getRowId={(row) => row._id}
            rowsPerOptions={[20, 50, 100]}
            columnVisibilityModel={columnVisibilityModel}
            onColumnVisibilityModelChange={(newModel) =>
              setColumnVisibilityModel(newModel)
            }
            columns={columns}
            pagination
            page={page}
            pageSize={pageSize}
            paginationMode="client"
            onPageChange={(newPage) => setPage(newPage)}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            rows={item.filter((row) =>
              Object.values(row).some(
                (value) =>
                  value &&
                  value
                    .toString()
                    .toLowerCase()
                    .includes(searchInput.toLowerCase())
              )
            )}
            components={{ Toolbar: GridToolbar }}
          />
        </Box>
      )}
    </Box>
  );
};

export default Items;
