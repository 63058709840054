import React, { useEffect } from "react";
import { Box, CircularProgress, Typography, useTheme } from "@mui/material";
import { api2 } from "state/api";
import { useState } from "react";
import { ResponsiveLine } from "@nivo/line";
import useAxiosPrivate from "hooks/useAxiosPrivate"

const InventoryChart = ({ isDashboard = false }) => {
  const theme = useTheme();
  const api = useAxiosPrivate()
  const [inventory, setInventory] = useState([]);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    api.get("api/dashboard/inventory/finished").then((response) => {
      setLoading(false);
      setInventory(response.data);
    });
  }, []);

  const colors = [theme.palette.secondary[500]];

  const formattedData = [
    {
      id: "Inventários Finalizados",
      data: inventory.map((item) => ({
        x: item.createdAt, // Supondo que createdAt seja uma string no formato "dd/mm/yyyy"
        y: item.count,
      })),
    },
  ];

  return (
    <Box
      height={isDashboard ? "500px" : "100%"}
      width={undefined}
      minHeight={isDashboard ? "325px" : undefined}
      minWidth={isDashboard ? "325px" : undefined}
      position="relative"
    >
      {loading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "50vh",
          }}
        >
          <CircularProgress color="secondary" />
        </Box>
      ) : (
        <ResponsiveLine
          data={formattedData}
          theme={{
            axis: {
              domain: {
                line: {
                  stroke: theme.palette.secondary[200],
                },
              },
              legend: {
                text: {
                  fill: theme.palette.secondary[200],
                },
              },
              ticks: {
                line: {
                  stroke: theme.palette.secondary[200],
                  strokeWidth: 1,
                },
                text: {
                  fill: theme.palette.secondary[200],
                  fontSize: isDashboard ? 8.25 : 12,
                },
              },
            },
            legends: {
              text: {
                fill: theme.palette.secondary[200],
              },
            },
            tooltip: {
              container: {
                color: theme.palette.primary.main,
              },
            },
          }}
          colors={colors}
          margin={
            isDashboard
              ? { top: 40, right: 40, bottom: 100, left: 50 }
              : { top: 40, right: 80, bottom: 80, left: 80 }
          }
          xScale={{ type: "point" }}
          yScale={{ type: "linear", min: 0, max: "auto", stacked: true, reverse: false }}
          axisBottom={{
            orient: "bottom",
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: "Data",
            legendOffset: 36,
            legendPosition: "middle",
          }}
          axisLeft={{
            orient: "left",
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: "Quantidade",
            legendOffset: -40,
            legendPosition: "middle",
            tickValues: "axisLeft.tickValues ? axisLeft.tickValues : 'auto'",
          }}
          enableGridX={false}
          enableGridY={false}
          enablePoints={false}
          enableArea={true}
          curve="monotoneX"
          lineWidth={3}
          pointSize={10}
          pointColor={{ theme: "background" }}
          pointBorderWidth={2}
          pointBorderColor={{ from: "serieColor" }}
          pointLabelYOffset={-12}
          useMesh={true}
        />
      )}
      <Box
        position="absolute"
        top="50%"
        left="50%"
        color={theme.palette.secondary[400]}
        textAlign="center"
        pointerEvents="none"
        sx={{
          transform: isDashboard
            ? "translate(-75%, -170%)"
            : "translate(-50%, -100%)",
        }}
      >
        <Typography variant="h6">
          {/* {!isDashboard && "Total:"} ${data.yearlySalesTotal} */}
        </Typography>
      </Box>
    </Box>
  );
};

export default InventoryChart;
