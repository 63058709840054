import React, { useCallback, useEffect, useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Typography,
  useTheme,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";

import LayersIcon from "@mui/icons-material/Layers";
import SendIcon from "@mui/icons-material/Send";
import EditIcon from "@mui/icons-material/Edit";
import ToggleOffOutlinedIcon from "@mui/icons-material/ToggleOffOutlined";
import ToggleOnIcon from "@mui/icons-material/ToggleOn";

import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

import useAxiosPrivate from "hooks/useAxiosPrivate";

import Input from "components/Input";
import ModalStyle from "components/ModalStyle";
import GridToolbar from "components/GridToolbar";
import ResponsiveFlexBetween from "components/ResponsiveFlexBetween";
import DataGridCustomToolbar from "components/DataGridCustomToolbar";
import Header from "components/Header";

const ItemGroup = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const axiosPrivate = useAxiosPrivate();

  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(20);

  const [itemGroupCode, setItemGroupCode] = useState("");
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [depreciation, setDepreciation] = useState("");

  const [searchInput, setSearchInput] = useState("");

  const [itemGroup, setItemGroup] = useState([]);

  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [isConfirmationModalOpen, setConfirmationModalOpen] = useState(false);

  const [editItemGroup, setEditItemGroup] = useState(null);
  const [inactivateItemGroup, setInactivateItemGroup] = useState(null);

  const showToastSuccess = (message) => {
    toast.success(message, {
      position: toast.POSITION.TOP_CENTER,
    });
  };

  const showToastWarning = (message) => {
    toast.warning(message, {
      position: toast.POSITION.TOP_CENTER,
    });
  };

  const showToastError = (message) => {
    toast.error(message, {
      position: toast.POSITION.TOP_CENTER,
    });
  };

  const handleOpen = (itemGroup = null) => {
    setEditItemGroup(itemGroup);
    setItemGroupCode(itemGroup ? itemGroup.itemGroupCode : "");
    setName(itemGroup ? itemGroup.name : "");
    setDescription(itemGroup ? itemGroup.description : "");
    setDepreciation(itemGroup ? itemGroup.depreciation : "");
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSearch = (searchInput) => {
    setSearchInput(searchInput);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      if (editItemGroup && editItemGroup._id) {
        const response = await axiosPrivate.put(
          `api/item-group/${editItemGroup._id}`,
          {
            name,
            description,
            depreciation,
          }
        );
        showToastSuccess(response.data.msg);
      } else {
        const response = await axiosPrivate.post("api/item-group", {
          itemGroupCode,
          name,
          description,
          depreciation,
        });
        showToastSuccess(response.data.msg);
      }

      setItemGroupCode("");
      setName("");
      setDescription("");
      setDepreciation("");
      setEditItemGroup(null);
      setOpen(false);
      loadData();
    } catch (error) {
      if (error.response.status === 400) {
        showToastWarning(error.response.data.error);
      } else if (error.response.status === 401) {
        showToastError("Acesso expirado. Faça o login novamente.");
        navigate("/");
      } else {
        showToastError(error.response.data.error || "Erro interno no servidor");
      }
    }
  };

  const loadData = useCallback(async () => {
    try {
      const response = await axiosPrivate.get("api/item-group");
      setItemGroup(response.data);
      setLoading(false);
    } catch (error) {
      if (error.response.status === 400) {
        showToastWarning(error.response.data.error);
      } else if (error.response.status === 401) {
        showToastError("Acesso expirado. Faça o login novamente.");
        navigate("/");
      } else {
        showToastError(error.response.data.error || "Erro interno no servidor");
        console.error("Erro ao carregar dados da tabela:", error);
      }
      setLoading(false);
    }
  }, [axiosPrivate]);

  async function handleActivateOrInactivateClick() {
    try {
      let response;

      if (inactivateItemGroup.active === "Ativo") {
        response = await axiosPrivate.put(
          `api/item-group/inactive/${inactivateItemGroup._id}`
        );
      } else if (inactivateItemGroup.active === "Inativo") {
        response = await axiosPrivate.put(
          `api/item-group/active/${inactivateItemGroup._id}`
        );
      }

      if (response && response.status === 200) {
        loadData();
        showToastSuccess(response.data.msg);
      } else {
        showToastError(response?.data?.error);
      }
    } catch (error) {
      showToastError(error.response?.data?.error);
      console.error("Erro ao inativar/ativar o grupo de itens:", error);
    }
  }

  useEffect(() => {
    loadData();
  }, [loadData]);

  const columns = [
    {
      field: "itemGroupCode",
      headerName: "Código",
      flex: 1,
    },
    {
      field: "name",
      headerName: "Grupo de itens",
      flex: 1,
    },
    {
      field: "description",
      headerName: "Descrição",
      flex: 1,
    },
    {
      field: "depreciation",
      headerName: "Taxa de depreciação",
      flex: 1,
    },
    {
      field: "active",
      headerName: "Status",
      flex: 1,
    },
    {
      field: "Ação",
      flex: 1,
      renderCell: (cellValues) => (
        <>
          <Button
            variant="text"
            color="secondary"
            onClick={() => handleOpen(cellValues.row)}
          >
            <EditIcon />
          </Button>
          <Button
            variant="text"
            color="error"
            onClick={() => {
              setConfirmationModalOpen(true);
              setInactivateItemGroup(cellValues.row);
            }}
          >
            {cellValues.row.active === "Ativo" ? (
              <ToggleOnIcon />
            ) : (
              <ToggleOffOutlinedIcon />
            )}
          </Button>
        </>
      ),
    },
  ];

  return (
    <Box m="1.5rem 2.5rem">
      <ResponsiveFlexBetween>
        <Header
          title="GRUPO DE ITENS"
          subtitle="Gerencie a lista do grupos de itens: visualize, cadastre, inative e edite."
        />
        <ModalStyle
          open={isConfirmationModalOpen}
          onClose={() => setConfirmationModalOpen(false)}
        >
          <Typography sx={{ mb: "30px" }}>
            {inactivateItemGroup && inactivateItemGroup.active === "Inativo"
              ? "Tem certeza de que deseja ativar este grupo de itens?"
              : "Tem certeza que deseja inativar este grupo de itens?"}
          </Typography>
          <ResponsiveFlexBetween>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => {
                handleActivateOrInactivateClick();
                setConfirmationModalOpen(false);
              }}
            >
              Confirmar
            </Button>
            <Button
              variant="contained"
              color="error"
              onClick={() => setConfirmationModalOpen(false)}
            >
              Cancelar
            </Button>
          </ResponsiveFlexBetween>
        </ModalStyle>
        <Box>
          <Button
            onClick={handleOpen}
            startIcon={<LayersIcon />}
            sx={{
              backgroundColor: theme.palette.secondary.dark,
              color: theme.palette.background.alt,
              fontSize: "14px",
              fontWeight: "bold",
              padding: "10px 42px",
              transition: "background-color 0.3s ease, color 0.3s ease",
              "&:hover": {
                backgroundColor: theme.palette.secondary.main,
                color: theme.palette.primary.main,
              },
            }}
          >
            Novo Grupo
          </Button>
          <ModalStyle open={open} onClose={handleClose}>
            <Typography id="modal-modal-title" variant="h5" component="h1">
              {editItemGroup && editItemGroup._id
                ? "Editar Grupo de Itens"
                : "Novo Grupo de Itens"}
            </Typography>
            <Box sx={{ mt: 2 }}>
              <form onSubmit={handleSubmit} className="form">
                <Input
                  type="number"
                  label="Código do grupo de itens"
                  required
                  value={itemGroupCode}
                  onChange={(e) => setItemGroupCode(e.target.value)}
                />
                <Input
                  type="text"
                  label="Nome do Grupo"
                  required
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
                <Input
                  type="text"
                  required
                  label="Descrição do Grupo"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                />
                <Input
                  type="number"
                  label="Taxa de depreciação"
                  required
                  value={depreciation}
                  onChange={(e) => setDepreciation(e.target.value)}
                />
                <Button
                  type="submit"
                  variant="contained"
                  color="secondary"
                  endIcon={<SendIcon />}
                >
                  {editItemGroup && editItemGroup._id
                    ? "Atualizar"
                    : "Cadastrar"}
                </Button>
              </form>
            </Box>
          </ModalStyle>
        </Box>
      </ResponsiveFlexBetween>
      <DataGridCustomToolbar
        value={searchInput}
        onChange={setSearchInput}
        onSearch={handleSearch}
      />
      {loading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "80vh",
          }}
        >
          <CircularProgress color="secondary" />
        </Box>
      ) : (
        <Box
          height="80vh"
          sx={{
            "& .MuiDataGrid-root": {
              border: "none",
            },
            "& .MuiDataGrid-cell": {
              borderBottom: "none",
            },
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: theme.palette.background.alt,
              color: theme.palette.secondary[100],
              borderBottom: "none",
            },
            "& .MuiDataGrid-virtualScroller": {
              backgroundColor: theme.palette.primary.light,
              overflowY: "auto",
              "&::-webkit-scrollbar": {
                width: "6px",
              },
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: "#b3b0b0",
                borderRadius: "50px",
              },
              "&::-webkit-scrollbar-track": {
                backgroundColor: "transparent",
              },
            },
            "& .MuiDataGrid-FooterContainer": {
              backgroundColor: theme.palette.background.alt,
              color: theme.palette.secondary[100],
              borderTop: "none",
            },
            "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
              color: `${theme.palette.secondary[200]} !important`,
            },
          }}
        >
          <DataGrid
            getRowId={(row) => row._id}
            rows={itemGroup.filter((row) =>
              Object.values(row).some(
                (value) =>
                  value &&
                  value
                    .toString()
                    .toLowerCase()
                    .includes(searchInput.toLowerCase())
              )
            )}
            rowsPerPageOptions={[20, 50, 100]}
            columns={columns}
            page={page}
            pageSize={pageSize}
            pagination
            paginationMode="client"
            onPageChange={(newPage) => setPage(newPage)}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            components={{ Toolbar: GridToolbar }}
          />
        </Box>
      )}
    </Box>
  );
};

export default ItemGroup;
