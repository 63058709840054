import React, { useEffect, useState } from "react";
import { Box, Typography, useTheme, useMediaQuery } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";

import DateRangeIcon from "@mui/icons-material/DateRange";
import MonetizationOnOutlinedIcon from "@mui/icons-material/MonetizationOnOutlined";
import AssessmentOutlinedIcon from "@mui/icons-material/AssessmentOutlined";
import HourglassEmptyOutlinedIcon from "@mui/icons-material/HourglassEmptyOutlined";

import { toast } from "react-toastify";

import { api2 } from "state/api";
import useAuth from "hooks/useAuth";
import useAxiosPrivate from "hooks/useAxiosPrivate";

import Header from "components/Header";
import StatBox from "components/StatBox";
import PieChart from "components/ItemGroupChart";
import BreakdownChart from "components/BreakdownChart";
import FlexBetween from "components/FlexBetween";
import InventoryChart from "components/InventoryChart";

const Dashboard = () => {
  const auth = useAuth();
  const date = new Date();
  const today = new Intl.DateTimeFormat("pt-BR", {
    dateStyle: "medium",
    timeStyle: "short",
  }).format(date);

  const theme = useTheme();
  const api = useAxiosPrivate();
  const isNonMediumScreens = useMediaQuery("(min-width: 1200px)");
  const [inventory, setInventory] = useState([]);
  const [data, setData] = useState([]);
  const [total, setTotal] = useState([]);
  const [totalValue, setTotalValue] = useState([]);
  const [totalByYear, setTotalByYear] = useState([]);
  const [averageAge, setAverageAge] = useState([]);

  const showToastError = (message) => {
    toast.error(message, {
      position: toast.POSITION.TOP_CENTER,
    });
  };

  useEffect(() => {
    api2
      .get("/api/dashboard/item/total")
      .then((response) => setTotal(response.data.QUANTIDADE));

    api2
      .get("/api/dashboard/item/total-value")
      .then((response) => {
        const totalValue = response.data ? response.data.totalValue : 0;
        setTotalValue(totalValue);
      })
      .catch((error) => {
        showToastError(error.response.data.error);
      });

    api.get("/api/inventory").then((response) => setInventory(response.data));

    api2
      .get("/api/dashboard/item/avarege-items")
      .then((response) => setAverageAge(response.data.avarageAge));

    api2
      .get("/api/dashboard/item/percentage-last-month")
      .then((response) => {
        const percentage = response.data ? response.data.percentage : 0;
        setData(percentage);
      })
      .catch((error) => {
        showToastError(error.response.data.error);
      });

    api2
      .get("/api/dashboard/item/total-value-year")
      .then((response) => {
        const totalByYear = response.data ? response.data.totalValue : 0;
        setTotalByYear(totalByYear);
      })
      .catch((error) => {
        showToastError(error.response.data.error);
      });
  }, [api]);

  const columns = [
    {
      field: "_id",
      headerName: "Inventário",
      flex: 1,
    },
    {
      field: "createdAt",
      headerName: "Inventariado",
      flex: 1,
    },
    {
      field: "user",
      headerName: "Responsável",
      flex: 0.5,
    },
  ];

  return (
    <Box m="1.5rem 2.5rem">
      <FlexBetween>
        <Header
          title="DASHBOARD"
          subtitle={`Bem-vindo, ${auth.auth.name}`}
          date={today}
        />
      </FlexBetween>

      <Box
        mt="20px"
        display="grid"
        gridTemplateColumns="repeat(12, 1fr)"
        gridAutoRows="160px"
        gap="20px"
        sx={{
          "& > div": { gridColumn: isNonMediumScreens ? undefined : "span 12" },
        }}
      >
        <StatBox
          title="Total de ativos"
          value={total}
          increase={data}
          description="Desde o mês passado"
          icon={
            <AssessmentOutlinedIcon
              sx={{ color: theme.palette.secondary[300], fontSize: "24px" }}
            />
          }
        />
        <StatBox
          title="Valor total"
          value={totalValue}
          description="Total em R$ dos ativos"
          icon={
            <MonetizationOnOutlinedIcon
              sx={{ color: theme.palette.secondary[300], fontSize: "24px" }}
            />
          }
        />
        <Box
          gridColumn="span 8"
          gridRow="span 2"
          backgroundColor={theme.palette.background.alt}
          p="1rem"
          borderRadius="0.55rem"
        >
          <BreakdownChart isDashboard={true} />
        </Box>
        <StatBox
          title="Valor total no ano"
          value={totalByYear}
          description="Valor total de aquisições no ano"
          icon={
            <DateRangeIcon
              sx={{ color: theme.palette.secondary[300], fontSize: "24px" }}
            />
          }
        />
        <StatBox
          title="Idade média do ativo"
          value={averageAge}
          description="Idade média do ativo em anos"
          icon={
            <HourglassEmptyOutlinedIcon
              sx={{ color: theme.palette.secondary[300], fontSize: "24px" }}
            />
          }
        />

        {/* <Box
          gridColumn="span 6"
          gridRow="span 3"
          sx={{
            "& .MuiDataGrid-root": {
              border: "none",
            },
            "& .MuiDataGrid-cell": {
              borderBottom: "none",
            },
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: theme.palette.background.alt,
              color: theme.palette.secondary[100],
              borderBottom: "none",
            },
            "& .MuiDataGrid-virtualScroller": {
              backgroundColor: theme.palette.primary.light,
              overflowY: "auto",
              scrollbarWidth: "thin",
              "&::-webkit-scrollbar": {
                width: "3px",
              },
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: "#b3b0b0",
                borderRadius: "20px",
              },
              "&::-webkit-scrollbar-track": {
                backgroundColor: "transparent",
              },
            },
            "& .MuiDataGrid-FooterContainer": {
              backgroundColor: theme.palette.background.alt,
              color: theme.palette.secondary[100],
              borderTop: "none",
            },
            "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
              color: `${theme.palette.secondary[200]} !important`,
            },
          }}
        >
          <DataGrid
            getRowId={(row) => row._id}
            rows={inventory || []}
            columns={columns}
          />
        </Box> */}
        <Box
          gridColumn="span 6"
          gridRow="span 3"
          backgroundColor={theme.palette.background.alt}
          p="1.5rem"
          borderRadius="0.55rem"
        >
          <Typography variant="h6" sx={{ color: theme.palette.secondary[100] }}>
            Inventários finalizados
          </Typography>
          <InventoryChart isDashboard={true} />
        </Box>
        <Box
          gridColumn="span 6"
          gridRow="span 3"
          backgroundColor={theme.palette.background.alt}
          p="1.5rem"
          borderRadius="0.55rem"
        >
          <Typography variant="h6" sx={{ color: theme.palette.secondary[100] }}>
            Itens por grupos
          </Typography>
          <PieChart isDashboard={true} />
        </Box>
      </Box>
    </Box>
  );
};

export default Dashboard;
