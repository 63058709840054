import React, { useEffect, useState, useCallback } from "react";
import {
  Box,
  Button,
  Typography,
  useTheme,
  CircularProgress,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";

import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import SendIcon from "@mui/icons-material/Send";
import EditIcon from "@mui/icons-material/Edit";
import ToggleOffOutlinedIcon from "@mui/icons-material/ToggleOffOutlined";
import ToggleOnIcon from "@mui/icons-material/ToggleOn";

import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

import useAxiosPrivate from "hooks/useAxiosPrivate";

import Header from "components/Header";
import ResponsiveFlexBetween from "components/ResponsiveFlexBetween";
import DataGridCustomToolbar from "components/DataGridCustomToolbar";
import ModalStyle from "components/ModalStyle";
import Input from "components/Input";
import GridToolbar from "components/GridToolbar";

const CostCenter = () => {
  const theme = useTheme();
  const api = useAxiosPrivate();
  const navigate = useNavigate();

  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(20);

  const [code, setCode] = useState("");
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");

  const [searchInput, setSearchInput] = useState("");

  const [costCenter, setCostCenter] = useState([]);

  const [open, setOpen] = useState(false);
  const [isConfirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [loading, setLoading] = useState(true);

  const [editCostCenter, setEditCostCenter] = useState(null);
  const [inactivateCostCenter, setInactivateCostCenter] = useState(null);

  const showToastSuccess = (message) => {
    toast.success(message, {
      position: toast.POSITION.TOP_CENTER,
    });
  };

  const showToastWarning = (message) => {
    toast.warning(message, {
      position: toast.POSITION.TOP_CENTER,
    });
  };

  const showToastError = (message) => {
    toast.error(message, {
      position: toast.POSITION.TOP_CENTER,
    });
  };

  const handleOpen = (costCenter = null) => {
    setEditCostCenter(costCenter);
    setCode(costCenter ? costCenter.code : "");
    setName(costCenter ? costCenter.name : "");
    setDescription(costCenter ? costCenter.description : "");
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSearch = (searchInput) => {
    setSearchInput(searchInput);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      if (editCostCenter && editCostCenter._id) {
        const response = await api.put(`api/cost-center/${costCenter._id}`, {
          code,
          name,
          description,
        });
        showToastSuccess(response.data.msg);
      } else {
        const response = await api.post("api/cost-center/", {
          code,
          name,
          description,
        });
        showToastSuccess(response.data.msg);
      }

      setCode("");
      setName("");
      setDescription("");
      setEditCostCenter(null);
      setOpen(false);
      loadData();
    } catch (error) {
      if (error.response.status === 400) {
        showToastWarning(error.response.data.error);
      } else if (error.response.status === 401) {
        showToastError("Acesso expirado. Faça o login novamente.");
        navigate("/");
      } else {
        showToastError(
          error.response.data.error || "Erro interno no servidor."
        );
      }
    }
  };
  const loadData = useCallback(async () => {
    try {
      const response = await api.get("api/cost-center");
      setCostCenter(response.data);
      setLoading(false);
    } catch (error) {
      if (error.response.status === 400) {
        showToastWarning(error.response.data.error);
      } else if (error.response.status === 401) {
        showToastError("Acesso expirado. Faça o login novamente.");
        navigate("/");
      } else {
        showToastError(error.response.data.error || "Erro interno no servidor");
        console.error("Erro ao carregar dados da tabela:", error);
      }
      setLoading(false);
    }
  }, [api]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  async function handleActivateOrInactivateClick() {
    try {
      let response;

      if (inactivateCostCenter.active === "Ativo") {
        response = await api.put(
          `api/cost-center/inactive/${inactivateCostCenter._id}`
        );
      } else if (inactivateCostCenter.active === "Inativo") {
        response = await api.put(
          `api/cost-center/active/${inactivateCostCenter._id}`
        );
      }

      if (response && response.status === 200) {
        loadData();
        showToastSuccess(response.data.msg);
      } else {
        showToastError(response?.data?.error);
      }
    } catch (error) {
      showToastError(error.response?.data?.error);
      console.error("Erro ao inativar/ativar o centro de custo:", error);
    }
  }

  const columns = [
    {
      field: "code",
      headerName: "Código",
      flex: 1,
    },
    {
      field: "name",
      headerName: "Nome",
      flex: 1,
    },
    {
      field: "description",
      headerName: "Descrição",
      flex: 1,
    },
    {
      field: "Ação",
      flex: 0.5,
      renderCell: (cellValues) => {
        return (
          <>
            <Button
              variant="text"
              color="secondary"
              onClick={() => handleOpen(cellValues.row)}
            >
              <EditIcon />
            </Button>
            <Button
              variant="text"
              color="error"
              onClick={() => {
                setConfirmationModalOpen(true);
                setInactivateCostCenter(cellValues.row);
              }}
            >
              {cellValues.row.active === "Ativo" ? (
                <ToggleOnIcon />
              ) : (
                <ToggleOffOutlinedIcon />
              )}
            </Button>
          </>
        );
      },
    },
  ];

  return (
    <Box m="1.5rem 2.5rem">
      <ResponsiveFlexBetween>
        <Header
          title="CENTROS DE CUSTO"
          subtitle="Gerencia a lista dos centros de custo: visualize, cadastre, inative e edite."
        />
        <ModalStyle
          open={isConfirmationModalOpen}
          onClose={() => setConfirmationModalOpen(false)}
        >
          <Typography sx={{ mb: "30px" }}>
            {inactivateCostCenter && inactivateCostCenter.active === "Inativo"
              ? "Tem certeza de que deseja ativar este centro de custo?"
              : "Tem certeza que deseja inativar este centro de custo?"}
          </Typography>
          <ResponsiveFlexBetween>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => {
                handleActivateOrInactivateClick();
                setConfirmationModalOpen(false);
              }}
            >
              Confirmar
            </Button>
            <Button
              variant="contained"
              color="error"
              onClick={() => setConfirmationModalOpen(false)}
            >
              Cancelar
            </Button>
          </ResponsiveFlexBetween>
        </ModalStyle>
        <Box>
          <Button
            onClick={handleOpen}
            startIcon={<AddCircleOutlineIcon />}
            sx={{
              backgroundColor: theme.palette.secondary.dark,
              color: theme.palette.background.alt,
              fontSize: "14px",
              fontWeight: "bold",
              padding: "10px",
              transition: "background-color 0.3s ease, color 0.3s ease",
              "&:hover": {
                backgroundColor: theme.palette.secondary.main,
                color: theme.palette.primary.main,
              },
            }}
          >
            NOVO CENTRO DE CUSTO
          </Button>
          <ModalStyle maxHeight="90%" open={open} onClose={handleClose}>
            <Typography id="modal-modal-title" variant="h5" component="h1">
              {editCostCenter && editCostCenter._id
                ? "Edição de centro de custo"
                : "Cadastro de centro de custo"}
            </Typography>
            <Box sx={{ mt: 2 }}>
              <form component="form" onSubmit={handleSubmit}>
                <Input
                  type="text"
                  label="Código"
                  value={code}
                  onChange={(e) => setCode(e.target.value)}
                  required
                />
                <Input
                  type="text"
                  label="Nome"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  required
                />
                <Input
                  type="text"
                  label="Descrição do centro de custo"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  required
                />
                <Button
                  type="submit"
                  variant="contained"
                  color="secondary"
                  endIcon={<SendIcon />}
                  sx={{ mr: 5 }}
                >
                  {editCostCenter && editCostCenter._id
                    ? "Atualizar"
                    : "Cadastrar"}
                </Button>
              </form>
            </Box>
          </ModalStyle>
        </Box>
      </ResponsiveFlexBetween>
      <DataGridCustomToolbar
        value={searchInput}
        onChange={setSearchInput}
        onSearch={handleSearch}
      />
      {loading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "80vh",
          }}
        >
          <CircularProgress color="secondary" />
        </Box>
      ) : (
        <Box
          height="80vh"
          sx={{
            "& .MuiDataGrid-root": {
              border: "none",
            },
            "& .MuiDataGrid-cell": {
              borderBottom: "none",
            },
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: theme.palette.background.alt,
              color: theme.palette.secondary[100],
              borderBottom: "none",
            },
            "& .MuiDataGrid-virtualScroller": {
              backgroundColor: theme.palette.primary.light,
              overflowY: "auto",
              "&::-webkit-scrollbar": {
                width: "6px",
              },
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: "#b3b0b0",
                borderRadius: "50px",
              },
              "&::-webkit-scrollbar-track": {
                backgroundColor: "transparent",
              },
            },
            "& .MuiDataGrid-FooterContainer": {
              backgroundColor: theme.palette.background.alt,
              color: theme.palette.secondary[100],
              borderTop: "none",
            },
            "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
              color: `${theme.palette.secondary[200]} !important`,
            },
          }}
        >
          <DataGrid
            getRowId={(row) => row._id}
            rowsPerOptions={[20, 50, 100]}
            columns={columns}
            pagination
            page={page}
            pageSize={pageSize}
            paginationMode="client"
            onPageChange={(newPage) => setPage(newPage)}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            rows={costCenter.filter((row) =>
              Object.values(row).some(
                (value) =>
                  value &&
                  value
                    .toString()
                    .toLowerCase()
                    .includes(searchInput.toLowerCase())
              )
            )}
            components={{ Toolbar: GridToolbar }}
          />
        </Box>
      )}
    </Box>
  );
};

export default CostCenter;
