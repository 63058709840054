import React, { useCallback, useEffect, useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Typography,
  useTheme,
  Grid,
  MenuItem,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import SendIcon from "@mui/icons-material/Send";
import PostAddIcon from "@mui/icons-material/PostAdd";
import InfoIcon from "@mui/icons-material/Info";

import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import locale from "dayjs/locale/pt-br";

import useAxiosPrivate from "hooks/useAxiosPrivate";

import Header from "components/Header";
import DataGridCustomToolbar from "components/DataGridCustomToolbar";
import Input from "components/Input";
import ModalStyle from "components/ModalStyle";
import GridToolbar from "components/GridToolbar";
import ResponsiveFlexBetween from "components/ResponsiveFlexBetween";
import Dropdown from "components/Dropdown";

const UnregistredTags = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const axiosPrivate = useAxiosPrivate();

  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(20);

  const [branch, setBranch] = useState("");
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [value, setValue] = useState("");
  const [responsable, setResponsable] = useState("");
  const [location, setLocation] = useState("");
  const [supplier, setSupplier] = useState("");
  const [serialNumber, setSerialNumber] = useState("");
  const [tag, setTag] = useState("");
  const [costCenter, setCostCenter] = useState("");
  const [itemGroup, setItemGroup] = useState("");
  const [invoice, setInvoice] = useState("");
  const [searchInput, setSearchInput] = useState("");

  const [tags, setTags] = useState([]);
  const [locationSelect, setLocationSelect] = useState([]);
  const [itemGroupData, setItemGroupData] = useState([]);
  const [costCenterData, setCostCenterData] = useState([]);
  const [branchData, setBranchData] = useState([]);

  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);

  const [acquisitionDate, setAcquisitionDate] = useState(null);

  const handleOpen = (item = null) => {
    setName(item ? item.name : "");
    setDescription(item ? item.description : "");
    setValue(item ? item.value : "");
    setSupplier(item ? item.supplier : "");
    setLocation(item ? item.locationId : "");
    setSerialNumber(item ? item.serialNumber : "");
    setTag(item ? item.tag : "");
    setBranch(item ? item.branchId : "");
    setResponsable(item ? item.responsable : "");
    setInvoice(item ? item.invoice : "");
    setItemGroup(item ? item.itemGroupId : "");
    setCostCenter(item ? item.costCenterId : "");

    setAcquisitionDate(
      item ? dayjs(item.acquisitionDate, { format: "DD/MM/YYYY" }) : null
    );

    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const showToastSuccess = (message) => {
    toast.success(message, {
      position: toast.POSITION.TOP_CENTER,
    });
  };

  const showToastWarning = (message) => {
    toast.warning(message, {
      position: toast.POSITION.TOP_CENTER,
    });
  };

  const showToastError = (message) => {
    toast.error(message, {
      position: toast.POSITION.TOP_CENTER,
    });
  };

  const handleSearch = (searchInput) => {
    setSearchInput(searchInput);
  };

  const loadData = useCallback(async () => {
    try {
      const response = await axiosPrivate.get("api/unregistred-tags");
      setTags(response.data);
      setLoading(false);
    } catch (error) {
      if (error.response.status === 400) {
        showToastWarning(error.response.data.error);
      } else if (error.response.status === 401) {
        showToastError("Acesso expirado. Faça o login novamente.");
        navigate("/");
      } else {
        showToastError(error.response.data.error || "Erro interno no servidor");
        console.error("Erro ao carregar dados da tabela:", error);
      }
      setLoading(false);
    }
  }, [axiosPrivate]);

  useEffect(() => {
    axiosPrivate
      .get("api/location/active")
      .then((response) => setLocationSelect(response.data));

    axiosPrivate
      .get("api/item-group/active")
      .then((response) => setItemGroupData(response.data));

    axiosPrivate
      .get("api/cost-center/active")
      .then((response) => setCostCenterData(response.data));

    axiosPrivate
      .get("api/branch/active")
      .then((response) => setBranchData(response.data));

    loadData();
  }, [loadData]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axiosPrivate.post("api/item/", {
        branch,
        name,
        description,
        value,
        responsable,
        location,
        supplier,
        serialNumber,
        tag,
        acquisitionDate,
        itemGroup,
        invoice,
        costCenter,
      });
      showToastSuccess(response.data.msg);
      setOpen(false);
      loadData();
    } catch (error) {
      showToastError(error.response.data.error);
    }
  };

  const columns = [
    {
      field: "_id",
      headerName: "Código",
      flex: 1,
    },
    {
      field: "tag",
      headerName: "Nº Patrimônio",
      flex: 1,
    },
    {
      field: "location",
      headerName: "Localização",
      flex: 1,
    },
    {
      field: "username",
      headerName: "Usuário",
      flex: 1,
    },
    {
      field: "createdAt",
      headerName: "Data de criação",
      flex: 1,
    },
    {
      field: "Ação",
      flex: 1.2,
      renderCell: (cellValues) => {
        return (
          <Button
            variant="text"
            color="secondary"
            onClick={() => handleOpen(cellValues.row)}
          >
            <PostAddIcon />
          </Button>
        );
      },
    },
  ];

  return (
    <Box m="1.5rem 2.5rem">
      <ResponsiveFlexBetween>
        <Header
          title="PATRIMÔNIOS NÃO CADASTRADOS"
          subtitle="Veja a lista de patrimônios que foram lidos, no entanto não estão cadastrados."
        />
        <Box>
          <ModalStyle
            maxHeight="90%"
            width="90%"
            open={open}
            onClose={handleClose}
          >
            <Typography id="modal-modal-title" variant="h5" component="h1">
              Cadastro de item
            </Typography>
            <Box id="modal-modal-description" sx={{ mt: 2 }}>
              <form component="form" onSubmit={handleSubmit}>
                <Grid
                  container
                  rowSpacing={{ md: 5 }}
                  columnSpacing={{ md: 2 }}
                  columns={{ xs: 4, sm: 8, md: 12 }}
                >
                  <Grid item xs={4}>
                    <Input
                      type="text"
                      label="Item"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      required
                    />
                  </Grid>
                  <Grid item xs={8}>
                    <Input
                      type="text"
                      label="Descrição do item"
                      value={description}
                      onChange={(e) => setDescription(e.target.value)}
                      required
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Input
                      type="number"
                      label="Valor do ativo"
                      value={value}
                      onChange={(e) => setValue(e.target.value)}
                      required
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <LocalizationProvider
                      dateAdapter={AdapterDayjs}
                      adapterLocale={locale}
                    >
                      <DatePicker
                        type="date"
                        label="Data de aquisição"
                        slotProps={{
                          textField: {
                            helperText:
                              "Preenchido automaticamente com a data atual. Você pode alterar conforme necessário.",
                          },
                        }}
                        format="DD/MM/YYYY"
                        value={acquisitionDate || null}
                        onChange={(newDate) => setAcquisitionDate(newDate)}
                        sx={{ width: "100%", mb: "15px" }}
                      />
                    </LocalizationProvider>
                  </Grid>
                  <Grid item xs={4}>
                    <Input
                      type="text"
                      label="Fornecedor"
                      value={supplier}
                      onChange={(e) => setSupplier(e.target.value)}
                      required
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Input
                      type="text"
                      label="Usuário responsável"
                      value={responsable}
                      onChange={(e) => setResponsable(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Dropdown
                      value={location}
                      onChange={(e) => setLocation(e.target.location)}
                      label="Localização"
                    >
                      {locationSelect.map((location) => (
                        <MenuItem key={location._id} value={location._id}>
                          {location.name}
                        </MenuItem>
                      ))}
                    </Dropdown>
                  </Grid>
                  <Grid item xs={4}>
                    <Input
                      type="text"
                      label="Número de série"
                      value={serialNumber}
                      onChange={(e) => setSerialNumber(e.target.value)}
                      required
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Input
                      type="text"
                      label="Número de patrimônio"
                      value={tag}
                      onChange={(e) => setTag(e.target.value)}
                      required
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Input
                      type="text"
                      label="Nota fiscal"
                      value={invoice}
                      onChange={(e) => setInvoice(e.target.value)}
                      required
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Dropdown
                      type="text"
                      label="Filial"
                      value={branch || ""}
                      onChange={(e) => setBranch(e.target.value)}
                      required
                    >
                      {branchData.map((data) => (
                        <MenuItem key={data._id} value={data._id}>
                          {data.name}
                        </MenuItem>
                      ))}
                    </Dropdown>
                  </Grid>
                  <Grid item xs={4}>
                    <Dropdown
                      type="text"
                      label="Grupo de itens"
                      value={itemGroup || ""}
                      onChange={(e) => setItemGroup(e.target.value)}
                      required
                    >
                      {itemGroupData.map((data) => (
                        <MenuItem key={data._id} value={data._id}>
                          {data.name}
                        </MenuItem>
                      ))}
                    </Dropdown>
                  </Grid>
                  <Grid item xs={4}>
                    <Dropdown
                      type="text"
                      label="Centro de Custo"
                      value={costCenter || ""}
                      onChange={(e) => setCostCenter(e.target.value)}
                    >
                      {costCenterData.map((data) => (
                        <MenuItem key={data._id} value={data._id}>
                          {data.description}
                        </MenuItem>
                      ))}
                    </Dropdown>
                  </Grid>
                </Grid>
                <Button
                  type="submit"
                  variant="contained"
                  color="secondary"
                  endIcon={<SendIcon />}
                  sx={{ mr: 5 }}
                >
                  Cadastrar
                </Button>
              </form>
            </Box>
          </ModalStyle>
        </Box>
      </ResponsiveFlexBetween>
      <Box display="flex" alignItems="center" sx={{ color: "warning.light" }}>
        <InfoIcon />
        <Typography variant="h5" component="h3">
          Os itens que já foram cadastrados serão excluídos automaticamente a
          cada uma hora.
        </Typography>
      </Box>
      <DataGridCustomToolbar
        value={searchInput}
        onChange={setSearchInput}
        onSearch={handleSearch}
      />
      {loading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "80vh",
          }}
        >
          <CircularProgress color="secondary" />
        </Box>
      ) : (
        <Box
          height="80vh"
          sx={{
            "& .MuiDataGrid-root": {
              border: "none",
            },
            "& .MuiDataGrid-cell": {
              borderBottom: "none",
            },
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: theme.palette.background.alt,
              color: theme.palette.secondary[100],
              borderBottom: "none",
            },
            "& .MuiDataGrid-virtualScroller": {
              backgroundColor: theme.palette.primary.light,
              overflowY: "auto",
              scrollbarWidth: "thin",
              "&::-webkit-scrollbar": {
                width: "3px",
              },
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: "#b3b0b0",
                borderRadius: "20px",
              },
              "&::-webkit-scrollbar-track": {
                backgroundColor: "transparent",
              },
            },
            "& .MuiDataGrid-FooterContainer": {
              backgroundColor: theme.palette.background.alt,
              color: theme.palette.secondary[100],
              borderTop: "none",
            },
            "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
              color: `${theme.palette.secondary[200]} !important`,
            },
          }}
        >
          <DataGrid
            getRowId={(row) => row._id}
            rows={tags.filter((row) =>
              Object.values(row).some(
                (value) =>
                  value &&
                  value
                    .toString()
                    .toLowerCase()
                    .includes(searchInput.toLowerCase())
              )
            )}
            rowsPerPageOptions={[20, 50, 100]}
            columns={columns}
            pagination
            page={page}
            pageSize={pageSize}
            paginationMode="client"
            onPageChange={(newPage) => setPage(newPage)}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            components={{ Toolbar: GridToolbar }}
          />
        </Box>
      )}
    </Box>
  );
};

export default UnregistredTags;
