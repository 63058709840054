import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  MenuItem,
  useTheme,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import Typography from "@mui/material/Typography";

import VisibilityIcon from "@mui/icons-material/Visibility";
import SendIcon from "@mui/icons-material/Send";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import PlaylistPlayIcon from "@mui/icons-material/PlaylistPlay";

import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import useAxiosPrivate from "hooks/useAxiosPrivate";

import ResponsiveFlexBetween from "components/ResponsiveFlexBetween";
import GridToolbar from "components/GridToolbar";
import DataGridCustomToolbar from "components/DataGridCustomToolbar";
import Dropdown from "components/Dropdown";
import ModalStyle from "components/ModalStyle";
import Header from "components/Header";

const FirstReader = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const api = useAxiosPrivate();

  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(20);

  const [isItemsModalOpen, setIsItemsModalOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [openMyFirstReaderModal, setOpenMyFirstReaderModal] = useState(false);

  const [itemsForFirstReader, setItemsForFirstReader] = useState([]);
  const [data, setData] = useState([]);
  const [locationSelect, setLocationSelect] = useState([]);
  const [costCenterSelect, setCostCenterSelect] = useState([]);
  const [openFirstReader, setOpenFirstReader] = useState([]);

  const [searchInput, setSearchInput] = useState("");
  const [selectedLocation, setSelectedLocation] = useState("");
  const [selectedOpenLocation, setSelectedOpenLocation] = useState("");
  const [selectedCostCenter, setSelectedCostCenter] = useState("");

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpenItemsModal = async (row) => {
    try {
      const response = await api.get(`api/first-reader/${row._id}`);
      setItemsForFirstReader(response.data);
      setIsItemsModalOpen(true);
    } catch (error) {
      console.error("Erro ao obter itens da primeira leitura:", error);
      showToastError(error.response.data.error);
      handleCloseItemsModal();
    }
  };

  const handleCloseItemsModal = () => {
    setIsItemsModalOpen(false);
  };

  const handleOpenMyFirstReaderModal = () => {
    setOpenMyFirstReaderModal(true);
  };

  const handleCloseMyFirstReaderModal = () => {
    setOpenMyFirstReaderModal(false);
  };

  const handleSearch = (searchInput) => {
    setSearchInput(searchInput);
  };

  const showToastWarning = (message) => {
    toast.warning(message, {
      position: toast.POSITION.TOP_CENTER,
    });
  };

  const showToastError = (message) => {
    toast.error(message, {
      position: toast.POSITION.TOP_CENTER,
    });
  };

  const handleNewFirstReader = async (location, costCenter) => {
    try {
      const response = await api.post("api/first-reader", {
        location,
        costCenter,
      });
      const firstReaderId = response.data.firstReaderId;
      navigate(`/primeira-leitura/tags?leitura=${firstReaderId}`);
    } catch (error) {
      if (error.response.status === 400) {
        showToastWarning(error.response.data.error);
      } else if (error.response.status === 401) {
        showToastError("Acesso expirado. Faça o login novamente.");
        navigate("/");
      } else {
        showToastError(error.response.data.error);
      }
    }
  };

  const navigateMyFirstReader = () => {
    navigate(`/primeira-leitura/tags?leitura=${selectedOpenLocation}`);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const locationResponse = await api.get("api/location/first-reader");
        setLocationSelect(locationResponse.data);

        const costCenterResponse = await api.get("api/cost-center/active");
        setCostCenterSelect(costCenterResponse.data);

        api
          .get("api/first-reader/open/location")
          .then((response) => setOpenFirstReader(response.data));

        const firstReaderResponse = await api.get("/api/first-reader");
        setData(firstReaderResponse.data);
        setLoading(false);
      } catch (error) {
        handleApiError(error);
      }
    };

    fetchData();
  }, [api, navigate]);

  const handleApiError = (error) => {
    if (error.response) {
      if (error.response.status === 400) {
        showToastWarning(error.response.data.error);
      } else if (error.response.status === 401) {
        showToastError("Acesso expirado. Faça o login novamente.");
        console.error("Erro ao carregar dados da tabela: ", error);
        navigate("/");
      } else {
        showToastError(error.response.data.error);
      }
    } else {
      showToastError("Erro desconhecido ao fazer a requisição.");
      console.error("Erro desconhecido ao fazer a requisição: ", error);
    }

    setLoading(false);
  };

  const columns = [
    {
      field: "_id",
      headerName: "Código",
      flex: 0.7,
    },
    {
      field: "createdAt",
      headerName: "Data da primeira leitura",
      flex: 1,
    },
    {
      field: "location",
      headerName: "Localização",
      flex: 1,
    },
    {
      field: "costCenter",
      headerName: "Centro de custo",
      flex: 1,
    },
    {
      field: "user",
      headerName: "Responsável",
      flex: 0.5,
    },
    {
      field: "Ação",
      flex: 0.5,
      renderCell: (cellValues) => (
        <>
          <Button
            variant="contained"
            color="secondary"
            sx={{ marginRight: "5px" }}
            onClick={() => handleOpenItemsModal(cellValues.row)}
          >
            <VisibilityIcon />
          </Button>
        </>
      ),
    },
  ];

  return (
    <Box m="1.5rem 2.5rem">
      <ResponsiveFlexBetween>
        <Header
          title="PRIMEIRA LEITURA"
          subtitle="Veja a lista das primeiras leituras realizadas"
        />
        <ModalStyle
          open={isItemsModalOpen}
          onClose={handleCloseItemsModal}
          width="90%"
        >
          <Typography variant="h6" component="h2" sx={{ mb: "1rem" }}>
            Itens da primeira leitura
          </Typography>
          <Box
            height="50vh"
            width="100%"
            sx={{
              "& .MuiDataGrid-root": {
                border: "none",
              },
              "& .MuiDataGrid-cell": {
                borderBottom: "none",
              },
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: theme.palette.background.alt,
                color: theme.palette.secondary[100],
                borderBottom: "none",
              },
              "& .MuiDataGrid-virtualScroller": {
                backgroundColor: theme.palette.primary.light,
                overflowY: "auto",
                "&::-webkit-scrollbar": {
                  width: "6px",
                },
                "&::-webkit-scrollbar-thumb": {
                  backgroundColor: "#b3b0b0",
                  borderRadius: "50px",
                },
                "&::-webkit-scrollbar-track": {
                  backgroundColor: "transparent",
                },
              },
              "& .MuiDataGrid-FooterContainer": {
                backgroundColor: theme.palette.background.alt,
                color: theme.palette.secondary[100],
                borderTop: "none",
              },
              "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                color: `${theme.palette.secondary[200]} !important`,
              },
            }}
          >
            <DataGrid
              rows={itemsForFirstReader.map((item, index) => ({
                ...item,
                id: index,
              }))}
              columns={[
                { field: "itemId", headerName: "Código", flex: 0.7 },
                { field: "name", headerName: "Item", flex: 1 },
                { field: "description", headerName: "Descrição", flex: 1 },
                { field: "location", headerName: "Localização", flex: 1 },
                {
                  field: "serialNumber",
                  headerName: "Número de série",
                  flex: 1,
                },
                { field: "tag", headerName: "Patrimônio", flex: 1 },
              ]}
              pageSize={20}
              pagination
              components={{ Toolbar: GridToolbar }}
            />
          </Box>
        </ModalStyle>
        <Box>
          <ModalStyle
            open={openMyFirstReaderModal}
            onClose={handleCloseMyFirstReaderModal}
          >
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              sx={{ mb: "1rem" }}
            >
              Primeiras leituras abertas
            </Typography>
            <Dropdown
              sx={{
                width: "25rem",
                mb: "1rem",
                borderRadius: "8px",
                padding: "8px",
              }}
              value={selectedOpenLocation}
              onChange={(e) => setSelectedOpenLocation(e.target.value)}
              label="Selecione a primeira leitura"
            >
              {openFirstReader.map((firstReader) => (
                <MenuItem key={firstReader.firstReaderId} value={firstReader.firstReaderId}>
                  {firstReader.firstReaderId}
                </MenuItem>
              ))}
            </Dropdown>

            <Button
              variant="contained"
              color="secondary"
              endIcon={<SendIcon />}
              onClick={() => navigateMyFirstReader()}
            >
              Avançar
            </Button>
          </ModalStyle>
        </Box>
        <Box>
          <ModalStyle open={open} onClose={handleClose}>
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              sx={{ mb: "1rem" }}
            >
              Localização que está realizando a primeira leitura
            </Typography>
            <Dropdown
              sx={{
                width: "25rem",
                mb: "1rem",
                borderRadius: "8px",
                padding: "8px",
              }}
              value={selectedLocation}
              onChange={(e) => setSelectedLocation(e.target.value)}
              label="Selecione a localização"
              id="location-select"
            >
              {locationSelect.map((location) => (
                <MenuItem key={location._id} value={location._id}>
                  {location.name}
                </MenuItem>
              ))}
            </Dropdown>
            <Dropdown
              sx={{
                width: "25rem",
                mb: "1rem",
                borderRadius: "8px",
                padding: "8px",
              }}
              value={selectedCostCenter}
              onChange={(e) => setSelectedCostCenter(e.target.value)}
              label="Selecione o centro de custo"
            >
              {costCenterSelect.map((costCenter) => (
                <MenuItem key={costCenter._id} value={costCenter._id}>
                  {costCenter.description}
                </MenuItem>
              ))}
            </Dropdown>

            <Button
              variant="contained"
              color="secondary"
              endIcon={<SendIcon />}
              onClick={() =>
                handleNewFirstReader(selectedLocation, selectedCostCenter)
              }
            >
              Avançar
            </Button>
          </ModalStyle>
        </Box>
      </ResponsiveFlexBetween>
      <ResponsiveFlexBetween>
        <Button
          onClick={handleOpenMyFirstReaderModal}
          startIcon={<PlaylistPlayIcon />}
          sx={{
            backgroundColor: theme.palette.secondary.dark,
            color: theme.palette.background.default,
            fontSize: "14px",
            fontWeight: "bold",
            padding: "10px 23px",
            mb: "7px",
            transition: "background-color 0.3s ease, color 0.3s ease",
            "&:hover": {
              backgroundColor: theme.palette.secondary.main,
              color: theme.palette.primary.main,
            },
          }}
        >
          PRIMEIRAS LEITURAS ABERTAS
        </Button>
        <Button
          onClick={handleOpen}
          startIcon={<AddCircleOutlineOutlinedIcon />}
          sx={{
            backgroundColor: theme.palette.secondary.dark,
            color: theme.palette.background.default,
            fontSize: "14px",
            fontWeight: "bold",
            padding: "10px 23px",
            mb: "7px",
            transition: "background-color 0.3s ease, color 0.3s ease",
            "&:hover": {
              backgroundColor: theme.palette.secondary.main,
              color: theme.palette.primary.main,
            },
          }}
        >
          NOVA PRIMEIRA LEITURA
        </Button>
      </ResponsiveFlexBetween>
      <DataGridCustomToolbar
        value={searchInput}
        onChange={setSearchInput}
        onSearch={handleSearch}
      />
      {loading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "80vh",
          }}
        >
          <CircularProgress color="secondary" />
        </Box>
      ) : (
        <Box
          height="80vh"
          sx={{
            "& .MuiDataGrid-root": {
              border: "none",
            },
            "& .MuiDataGrid-cell": {
              borderBottom: "none",
            },
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: theme.palette.background.alt,
              color: theme.palette.secondary[100],
              borderBottom: "none",
            },
            "& .MuiDataGrid-virtualScroller": {
              backgroundColor: theme.palette.primary.light,
              overflowY: "auto",
              scrollbarWidth: "thin",
              "&::-webkit-scrollbar": {
                width: "3px",
              },
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: "#b3b0b0",
                borderRadius: "20px",
              },
              "&::-webkit-scrollbar-track": {
                backgroundColor: "transparent",
              },
            },
            "& .MuiDataGrid-FooterContainer": {
              backgroundColor: theme.palette.background.alt,
              color: theme.palette.secondary[100],
              borderTop: "none",
            },
            "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
              color: `${theme.palette.secondary[200]} !important`,
            },
          }}
        >
          <DataGrid
            getRowId={(row) => row._id}
            rows={data.filter((row) =>
              Object.values(row).some(
                (value) =>
                  value &&
                  value
                    .toString()
                    .toLowerCase()
                    .includes(searchInput.toLowerCase())
              )
            )}
            rowsPerOptions={[20, 50, 100]}
            columns={columns}
            pagination
            page={page}
            pageSize={pageSize}
            paginationMode="client"
            onPageChange={(newPage) => setPage(newPage)}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            components={{ Toolbar: GridToolbar }}
          />
        </Box>
      )}
    </Box>
  );
};

export default FirstReader;
