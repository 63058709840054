import React, { useEffect, useState, useCallback } from "react";
import {
  Box,
  Button,
  Typography,
  MenuItem,
  CircularProgress,
  useTheme,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";

import SendIcon from "@mui/icons-material/Send";
import CompareArrowsIcon from "@mui/icons-material/CompareArrows";

import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

import useAxiosPrivate from "hooks/useAxiosPrivate";

import DataGridCustomToolbar from "components/DataGridCustomToolbar";
import Header from "components/Header";
import Input from "components/Input";
import ModalStyle from "components/ModalStyle";
import Dropdown from "components/Dropdown";
import GridToolbar from "components/GridToolbar";
import ResponsiveFlexBetween from "components/ResponsiveFlexBetween";

const AssetMovement = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const api = useAxiosPrivate();

  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(20);

  const [tag, setTag] = useState("");
  const [actualLocation, setActualLocation] = useState("");
  const [newLocation, setNewLocation] = useState("");
  const [reason, setReason] = useState("");
  const [observations, setObservations] = useState("");

  const [searchInput, setSearchInput] = useState("");

  const [item, setItem] = useState([]);
  const [location, setLocation] = useState([]);
  const [movement, setMovement] = useState([]);

  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(true);

  const [itemSelected, setItemSelected] = useState(null);

  const showToastSuccess = (message) => {
    toast.success(message, {
      position: toast.POSITION.TOP_CENTER,
    });
  };

  const showToastWarning = (message) => {
    toast.warning(message, {
      position: toast.POSITION.TOP_CENTER,
    });
  };

  const showToastError = (message) => {
    toast.error(message, {
      position: toast.POSITION.TOP_CENTER,
    });
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    clearForm();
  };

  const handleSearch = (searchInput) => {
    setSearchInput(searchInput);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await api.post("api/movement/", {
        item: item[0]._id,
        newLocation,
        reason,
        observations,
      });

      showToastSuccess(response.data.msg);

      handleClose();
      loadData();
    } catch (error) {
      if (error.response.status === 401) {
        showToastError("Acesso expirado. Faça o login novamente.");
        navigate("/");
      } else {
        showToastError(
          error.response?.data?.error || "Erro interno no servidor."
        );
      }
    }
  };

  const loadData = useCallback(async () => {
    try {
      const response = await api.get("/api/movement");
      setMovement(response.data);
      setLoading(false);

      if (itemSelected) {
        updateItemDetails();
      }
    } catch (error) {
      if (error.response.status === 400) {
        showToastWarning(error.response.data.error);
      } else if (error.response.status === 401) {
        showToastError("Acesso expirado. Faça o login novamente.");
        navigate("/");
      } else {
        showToastError(error.response.data.error || "Erro interno no servidor");
        console.error("Erro ao carregar dados da tabela:", error);
      }
      setLoading(false);
    }
  }, [api, itemSelected]);

  const clearForm = () => {
    setTag("");
    setItemSelected("");
    setActualLocation("");
    setNewLocation("");
    setReason("");
    setObservations("");
  };

  useEffect(() => {
    loadData();

    if (itemSelected) {
      updateItemDetails();
    }

    api
      .get("/api/location/active")
      .then((response) => setLocation(response.data))
      .catch((error) => {
        if (error.response.status === 401) {
          showToastError("Acesso expirado. Faça o login novamente.");
          navigate("/");
        } else {
          showToastError(
            error.response?.data?.error || "Erro interno no servidor."
          );
        }
      });

    api
      .get("/api/item/active")
      .then((response) => setItem(response.data))
      .catch((error) => {
        if (error.response.status === 401) {
          showToastError("Acesso expirado. Faça o login novamente.");
          navigate("/");
        } else {
          showToastError(
            error.response?.data?.error || "Erro interno no servidor."
          );
        }
      });
  }, [api, loadData, itemSelected]);

  const updateItemDetails = async () => {
    try {
      if (itemSelected) {
        setActualLocation(itemSelected.location);
        setTag(itemSelected.tag);
      }
    } catch (error) {
      showToastError(error.response.data.error);
    }
  };

  const columns = [
    {
      field: "item",
      headerName: "Item",
      flex: 1,
    },
    {
      field: "newLocation",
      headerName: "Nova localização",
      flex: 1,
    },
    {
      field: "reason",
      headerName: "Motivo",
      flex: 1,
    },
    {
      field: "observations",
      headerName: "Observações",
      flex: 1,
    },
    {
      field: "user",
      headerName: "Responsável",
      flex: 1,
    },
    {
      field: "createdAt",
      headerName: "Data",
      flex: 1,
    },
  ];

  return (
    <Box m="1.5rem 2.5rem">
      <ResponsiveFlexBetween>
        <Header
          title="MOVIMENTAÇÃO DOS ATIVOS"
          subtitle="Veja a lista das movimentações dos ativos."
        />
        <Box>
          <Button
            onClick={handleOpen}
            startIcon={<CompareArrowsIcon />}
            sx={{
              backgroundColor: theme.palette.secondary.dark,
              color: theme.palette.background.alt,
              fontSize: "14px",
              fontWeight: "bold",
              padding: "10px 7px",
              transition: "background-color 0.3s ease, color 0.3s ease",
              "&:hover": {
                backgroundColor: theme.palette.secondary.main,
                color: theme.palette.primary.main,
              },
            }}
          >
            Nova Movimentação
          </Button>
          <ModalStyle open={open} onClose={handleClose}>
            <Typography id="modal-modal-title" variant="h5" component="h1">
              Nova movimentação do ativo
            </Typography>
            <Box sx={{ mt: 2 }}>
              <form onSubmit={handleSubmit}>
                <Dropdown
                  label="Item"
                  value={itemSelected ? itemSelected._id : ""}
                  onChange={(e) => {
                    const selectedItem = item.find(
                      (i) => i._id === e.target.value
                    );
                    setItemSelected(selectedItem);
                    updateItemDetails();
                  }}
                >
                  {item.map((item) => (
                    <MenuItem key={item._id} value={item._id}>
                      {item.name}
                    </MenuItem>
                  ))}
                </Dropdown>
                <Input
                  type="text"
                  label="Número de patrimônio"
                  value={tag}
                  disabled
                />
                <Input
                  type="text"
                  label="Localização Atual"
                  value={actualLocation}
                  disabled
                  color="error"
                />
                <Dropdown
                  label="Nova localização"
                  value={newLocation}
                  onChange={(e) => setNewLocation(e.target.value)}
                >
                  {location.map((loc) => (
                    <MenuItem key={loc._id} value={loc._id}>
                      {loc.name}
                    </MenuItem>
                  ))}
                </Dropdown>
                <Input
                  type="text"
                  label="Motivo"
                  value={reason}
                  onChange={(e) => setReason(e.target.value)}
                />
                <Input
                  type="text"
                  label="Observações"
                  value={observations}
                  onChange={(e) => setObservations(e.target.value)}
                />
                <Typography color="darkgray" sx={{ mb: "15px" }}>
                  Os campos Número de Patrimônio e Localização Atual são
                  preenchidos automaticamente, basta selecionar o item.
                </Typography>
                <Button
                  type="submit"
                  variant="contained"
                  color="secondary"
                  endIcon={<SendIcon />}
                >
                  Confirmar movimentação
                </Button>
              </form>
            </Box>
          </ModalStyle>
        </Box>
      </ResponsiveFlexBetween>
      <DataGridCustomToolbar
        value={searchInput}
        onChange={setSearchInput}
        onSearch={handleSearch}
      />
      {loading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "80vh",
          }}
        >
          <CircularProgress color="secondary" />
        </Box>
      ) : (
        <Box
          height="80vh"
          sx={{
            "& .MuiDataGrid-root": { border: "none" },
            "& .MuiDataGrid-cell": { borderBottom: "none" },
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: theme.palette.background.alt,
              color: theme.palette.secondary[100],
              borderBottom: "none",
            },
            "& .MuiDataGrid-virtualScroller": {
              backgroundColor: theme.palette.primary.light,
              overflowY: "auto",
              "&::-webkit-scrollbar": { width: "6px" },
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: "#b3b0b0",
                borderRadius: "50px",
              },
              "&::-webkit-scrollbar-track": { backgroundColor: "transparent" },
            },
            "& .MuiDataGrid-FooterContainer": {
              backgroundColor: theme.palette.background.alt,
              color: theme.palette.secondary[100],
              borderTop: "none",
            },
            "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
              color: `${theme.palette.secondary[200]} !important`,
            },
          }}
        >
          <DataGrid
            getRowId={(row) => row._id}
            rows={movement.filter((row) =>
              Object.values(row).some(
                (value) =>
                  value &&
                  value
                    .toString()
                    .toLowerCase()
                    .includes(searchInput.toLowerCase())
              )
            )}
            rowsPerPageOptions={[20, 50, 100]}
            columns={columns}
            pagination
            page={page}
            pageSize={pageSize}
            paginationMode="client"
            onPageChange={(newPage) => setPage(newPage)}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            components={{ Toolbar: GridToolbar }}
          />
        </Box>
      )}
    </Box>
  );
};

export default AssetMovement;
