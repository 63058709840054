import React from 'react';
import { Typography, Container, Paper, Box, Button } from '@mui/material';

const Unauthorized = () => {
  const rootStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
  };

  const paperStyle = {
    padding: '32px',
    textAlign: 'center',
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
    borderRadius: '8px',
  };

  const titleStyle = {
    fontSize: '1.5rem',
    fontWeight: 'bold',
    marginBottom: '16px',
    color: '#1976D2',
  };

  const messageStyle = {
    fontSize: '1rem',
    color: 'rgba(0, 0, 0, 0.6)',
  };

  const buttonStyle = {
    marginTop: '16px',
    color: '#fff',
    backgroundColor: '#2196F3',
    '&:hover': {
      backgroundColor: '#1565C0',
    },
  };

  const handleButtonClick = () => {
    window.open('mailto:seu.superior@facens.br?subject=Solicitação de Acesso');
  };

  return (
    <Container style={rootStyle}>
      <Paper style={paperStyle}>
        <Typography style={titleStyle}>
          Acesso Não Autorizado
        </Typography>
        <Typography style={messageStyle}>
          Infelizmente, você não possui permissão para acessar esta página.
          Entre em contato com o seu superior para solicitar acesso.
        </Typography>
        <Box mt={3}>
          <Button style={buttonStyle} variant="contained" onClick={handleButtonClick}>
            Solicitar Acesso
          </Button>
        </Box>
      </Paper>
    </Container>
  );
};

export default Unauthorized;
